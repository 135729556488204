
@font-face {
  font-family: Miniature;
  src: url("../public/AntipastoPro-Medium_trial.ttf");
}
html,
body,
#root,
.dashBG {
  height: 100%;
  width: 100%;
}

.Miniature{
  font-family: Miniature;
}
body {
  margin: 0;
  font-family: 'Roboto';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: linear-gradient(to bottom,black 5%,rgb(52, 52, 52)) !important;
  background-repeat: no-repeat;
  background-position:cover;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.fs-16{
  font-size: 16px ;
}

.gradient-text-right {
/*   background-image: linear-gradient(to right, #d6d300, #f3a006);
 */  /* background: linear-gradient(to bottom,black 5%,rgb(52, 52, 52)); */
/*   -webkit-background-clip: text;*/  
/* -webkit-text-fill-color: transparent;
 */  text-align: center;
}
.mintCard {
  border: 1px solid black;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.5); /* Adding box shadow */
  border-radius: 10px; /* Adding border radius for a smoother look */
  padding: 20px; /* Adding padding for better spacing */
}
.mintButton{
  font-size: 20px ;
  color:  white !important;
  background-color: #244CE1 !important;

}
.Nft-Sample{
  border: 1px solid black;
  height: 200px;
  width: 200px;
  display: flex;
  margin: auto;
  position: relative;
  border-radius: 5px;
  box-shadow: 0px 0px 20px #FF6700 ;
}

.Nft-Sample img {
  height: 100%;
  width: 100%;
}
.minth2{
  font-size: 20px;
  word-break: break-all !important;
}
.mint-font-26{
  font-size: 26px;
}
.svg-size{
  width: 35px;
}
.top_link{
  text-decoration: none;
  color: black;
  font-weight: 500;
  font-size: 18px;
}
.dashBG{
  background: url("./images/gen.png"), black;
  background-size: cover;
}
.bg{
  /* background: linear-gradient(to bottom,#f7af29 5%,rgb(255, 140, 0)); */
  background: linear-gradient(to bottom,black 5%,rgb(52, 52, 52));


}