.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@keyframes scaleLoop {
  from {
    transform-origin: center;
    transform: scale(1.1);
  }
  to {
    transform-origin: center;
    transform: scale(1.0);
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.container {
  border-bottom-left-radius: 30px !important;
  border-top-right-radius: 30px !important;
  border: 4px;
  border: solid;
  border-image: linear-gradient(to right bottom, #244CE1, rgba(0,0,0,1), #244CE1) 1;
  /* border-left: 2px solid linear-gradient(black 90%,  #244CE1) !important; */
  width: 100%;
}

.arrow {
  width: 50px;
}

.miniature-container {
  width: 70% !important;
  margin: auto !important;
}

.align-middle	{
  line-height: 100% !important;
  vertical-align: middle !important;
}

.a-text {
  line-height: 100% !important;
  margin: auto;
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.main-section {
  height: 80%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.anim-scale {
  display: inline-block;
  animation-duration: 1s;
  animation-name: scaleLoop;
  animation-iteration-count: infinite;
  align-items: middle;
}