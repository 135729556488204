.main-img {
    height: 50px;
    width: 50px;
    margin-top: 10px;
}

.text-main {
    color: white;
    font-family: 'Miniature';
    margin-top: 25px;
}

.connect-wallet {
    border-radius: 15px;
    padding: 3px 30px;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 400;
    border: none;
    justify-content: center;
    // box-shadow: 0 0 20px rgba(0, 0, 0, 0.5); /* Adding box shadow */
    float: right !important;
    color:  white !important;
    background-color: #244CE1 !important;

    @media screen and (max-width: 850px) {
        float: none !important;
        margin: auto !important;
        display: flex !important;
        margin-top: 20px !important;
    }
    
}
.connect-wallet:hover{
    
    background-color: rgb(22, 22, 22);
    box-shadow: 0 0 20px rgba(23, 21, 21, 0.5); /* Adding box shadow */

}


.sticky {
    // background-color: #f7af29;
    padding: 10px
}

.gradient-color {
/*     background-image: linear-gradient(to bottom, #f3a006 50%, #564119);
 *//*     -webkit-background-clip: text;
 */    /* -webkit-text-fill-color: transparent; */
    font-size: 25px;
}

.d-flex {
    @media screen and (max-width: 850px) {
        flex-direction: column !important;
    }
}

.timer {
    display: inline-block;
    margin: 0 10px;
    color: #fff;
    font-size: 24px;
  
    .value {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
  
      span:first-child {
        font-size: 36px;
        text-shadow: 0 0 20px rgba(254, 254, 254, 0.5); /* Adding text shadow */
      }
  
      span:last-child {
        font-size: 16px;
        text-shadow: 0 0 10px rgba(254, 254, 254, 0.5); /* Adding text shadow */
      }
    }
  }
  